@import "vars";
@import "mixins";

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }

html {
    background-color: $background-color;
    font-family: $font;
    font-size: 16px; }

body {
    font-size: $body-size;
    font-family: $font;
    color: $body-text;
    line-height: 2.5rem;
    text-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    opacity: 1; }

header {
    padding: 6em 1em 1em 0;
    @media screen and (max-width: 736px) {
        padding: 3em 0; } }

h1,h2,h3 {
    margin-bottom: 0.5em;
    font-family: $title-font;
    opacity: 0.6;
    line-height: 3rem; }

li {
    opacity: 0.8;
    & a {
        opacity: 0.9; } }

ul {
    list-style-type: none; }

p {
    display: inline; }

a {
    color: $links;
    text-decoration: none;
    border-bottom: 2px solid $links;
    @include ease(0.5s);
    &:hover {
        color: $links-hover;
        font-style: none; }
    &:active {
        color: $links; } }

hr {
    @include skinny-hr;
    opacity: 0.3; }

nav {
    margin: 1em auto; }

.wrap {
    margin: 0 auto;
    width: 45%;
    max-width: 64em;
    @media screen and (max-width: 736px) {
        width: 90%; } }

.tag-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }

.tag {
    background-color: transparent;
    position: relative;
    bottom: 0; }

.flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; }

.bottom-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; }

.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: transparent;
    position: relative;
    bottom: 0;
    padding: 1rem;
    font-family: $font;
    font-size: 1rem;
    line-height: 1em;
    opacity: 0.8;
    a {
        border-bottom: none;
        display: contents; } }

.nav-year {
    margin: 1em auto; }

@import "elements";


.go-left {
    justify-content: flex-start;
    align-items: flex-start; }

.go-right {
    justify-content: flex-end;
    align-items: flex-end; }

.go-center {
    justify-content: center;
    align-items: center; }
