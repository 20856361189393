@mixin skinny-hr {
    margin: 1rem 0;
    background-color: transparent;
    //box-shadow: 0 1px 0 rgba(219,219,219,0.3)
    width: 50%;
    border-style: solid;
    border-width: 1px; }

@mixin ease($time) {
    transition: $time ease; }
