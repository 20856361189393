$white: #fff;
$black: #111;
$dark-grey: #fff;
$background-color: #fff;
$font: 'EB Garamond', serif;
$title-font: 'Fira Sans', sans-serif;
$body-size: 16pt;
$article-size: 20pt;
$body-text: $black;

$links: lighten($black, 20%);
$links-hover: $black;

$code-block: #000004;
